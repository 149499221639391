import React, { FC, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { updateSettings } from '../actions'
import { DEFAULT_SYMBOL_NAME } from '../config/settings'
import { State } from '../reducers'
import { isChainReadOnly } from '../services/chainService'
import { getNetworkConfig } from '../utils'
import TVChartContainerFunc from './TVChartContainer/TVChartContainerFunc'

const Graph: FC = () => {
  const reduxDispatch = useDispatch()
  const mounted = useRef<boolean>()

  const activeToken = useSelector((state: State) => state.tokens.currentToken)
  const selectedCurrency = useSelector((state: State) => state.currency)
  const { tvChartHeight, isChartFullScreen } = useSelector(
    (state: State) => state.settings.settingsData
  )

  const [chartHeight, setChartHeight] = useState(isChartFullScreen ? '' : tvChartHeight)

  const saveHeight = (height?: string): void => {
    setChartHeight(height || '')
  }

  const timer = useRef<ReturnType<typeof setTimeout>>()

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
      return
    }

    if (timer) {
      clearTimeout(timer.current as ReturnType<typeof setTimeout>)
    }

    // debounce
    timer.current = setTimeout(() => {
      if (!isChartFullScreen) {
        reduxDispatch(updateSettings({ tvChartHeight: chartHeight }))
      }
    }, 500)

    return (): void => {
      if (timer) {
        clearTimeout(timer.current as ReturnType<typeof setTimeout>)
      }
    }
  }, [chartHeight, isChartFullScreen, reduxDispatch])

  if (!activeToken) {
    return null
  }

  const networkConfig = getNetworkConfig(activeToken.network)

  return (
    <TVChartContainerFunc
      symbolName={`${activeToken?.id || DEFAULT_SYMBOL_NAME}_${selectedCurrency}`}
      saveHeight={saveHeight}
      isReadOnlyMarket={isChainReadOnly(networkConfig)}
      hideResizeButton={isChartFullScreen}
    />
  )
}

export default Graph
